import React from 'react';

const FormInput = React.forwardRef((props, ref) => {
    const formClass = () => {
        if (props.className === undefined)
            return "form-control form-email p-4 text-center";
        else
            return props.className;
    }
    return (
        <div className="form-group px-3 text-center">
            <input id={props.id} name={props.name} type={props.type} placeholder={props.placeholder} className={formClass()} onChange={props.onChange } disabled={ props.disabled } ref={ ref }/>
            <small className="text-danger">
                { props.errorMessage && props.errorMessage[props.id] ? props.errorMessage[props.id] : null}
            </small>
        </div>
    );

});

export default FormInput;