import React, { Component } from 'react';
import {connect} from 'react-redux';

import MeteoChart from '../meteo/MeteoChart';
import StackedChart from '../dashboard/StackedChart';
import FooterMenu from '../layout/FooterMenu';

class DustCharts extends Component {


    render() {
        const locations = this.props.sensorLocations ? this.props.sensorLocations : []

        return (
            <div className="container-fluid p-0">

                <div className="col-12">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4 px-4 pt-3">
                            <MeteoChart />
                        </div>

                        {
                            locations.map((location) => (
                                <div className="col-12 col-md-6 col-xl-4 px-4 pt-3" key={location.id}>
                                    <StackedChart location_id={location.id} tooltip_x={0} />
                                </div>
                            ))
                        }
                    </div>
                </div>

                <FooterMenu />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sensorLocations: state.sensor.sensorLocations
    }
}



export default connect(mapStateToProps, null)(DustCharts);