import React from 'react';

const GenericForm = (props) => {
    
        if (props.name === "role") {
            const selectOptions = props.options.map( option => {
                return (
                    <option key={ option.value } value={ option.value }> {option.label} </option>
                )
            })

            return (
                <div className="form-group row">
                    <div className={props.classNameLabel}>
                        <label htmlFor="exampleFormControlSelect1">{props.labels}</label>
                    </div>

                    <div className={props.classNameInput}>
                        <select name={props.name} className="form-control" id={props.name}  onChange={props.onChange} value={props.value} disabled={ props.disabled }>
                            <option></option>
                            { selectOptions }
                        </select>
                        <small className="text-danger">
                            { props.errorMessage !== undefined && props.errorMessage !== null && props.errorMessage[props.name] !== null ? props.errorMessage[props.name] : null}
                        </small> 
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="form-group row">
                    <div className={props.classNameLabel}>
                        <label htmlFor="exampleFormControlInput1">{props.labels}</label>
                    </div>
                    <div className={props.classNameInput}>
                        <input name={props.name} type={props.type} className="form-control" id={props.name} onChange={props.onChange} value={props.values} disabled={ props.disabled }/>
                        <small className="text-danger">
                            { props.errorMessage !== undefined && props.errorMessage !== null && props.errorMessage[props.name] !== null ? props.errorMessage[props.name] : null}
                        </small> 
                    </div>   
                    
                    
                </div>
            );
        }
};

export default GenericForm;