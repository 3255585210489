import React, { Component } from 'react';
import axios from 'axios';
import GenericForm from '../forms_helper/GenericForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class AdminAddUser extends Component {
    initialState = {
        name: '',
        role: '',
        email: '',
        error: null,
        password_html: null,
        loading: false,

    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    constructor(props) {
        super(props);
        this.state = this.initialState;
    }



    handleSubmit = event => {
        event.preventDefault();
        this.setState({ loading: true });

        const user = {
            name: this.state.name,
            email: this.state.email,
            role: this.state.role
        };

        axios.post('api/users', { ...user })
            .then(res => {
                this.setState({
                    error: null,
                    password_html: res.data.password,
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    error: err.response.data,
                    loading: false
                })
            })
    }

    html_password = () => {
        if (this.state.password_html === null) {
            return null;
        } else {
            return (
                <div>
                    The password for the created user is: <br />
                    <h3> {this.state.password_html} </h3>
                    After the first login, new user will be prompted to change password.
                </div>
            )
        }
    }
    reset_state = () => {
        this.setState(this.initialState);

    }

    html_sucess_buttons = () => {
        if (this.state.password_html === null) {
            return null;
        } else {
            return (
                <div>
                    <button type="button" className="btn btn-info mx-1" onClick={() => this.props.history.push("/admin")}>
                        Finish
                </button>
                    <button type="button" className="btn btn-info mx-1" onClick={() => this.reset_state()}>
                        Add another user
                </button>
                </div>
            )
        }
    }

    html_createUser = () => {
        if (this.state.password_html === null ) {
            return (
                
                <form onSubmit={this.handleSubmit}>
                    <GenericForm
                        classNameLabel="col-2 font-weight-bold"
                        classNameInput="col-10"
                        labels="Name: "
                        name="name"
                        type="text"
                        onChange={this.handleChange}
                        values={this.state.name}
                    />

                    <GenericForm
                        classNameLabel="col-2 font-weight-bold"
                        classNameInput="col-10"
                        labels="Email: "
                        name="email"
                        type="text"
                        onChange={this.handleChange}
                        errorMessage={this.state.error}
                        values={this.state.email}
                    />
                    <GenericForm
                        classNameLabel="col-2 font-weight-bold"
                        classNameInput="col-10"
                        labels="Role: "
                        name="role"
                        onChange={this.handleChange}
                        errorMessage={this.state.error}
                        options={[
                            { label: "Admin", value: 2 },
                            { label: "User+", value: 3 },
                            { label: "User", value: 4 }
                        ]}
                        value={this.state.role}
                    />
                    <div className="row">
                        <div className="col-12 text-right">
                            <button type="submit" className="btn btn-info ">
                                {this.state.loading === true ? <FontAwesomeIcon icon="spinner" spin /> : "Create User"}
                            </button>
                        </div>
                    </div>
                </form>
                )
        } else {
            return null;
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-10 col-sm-8 col-md-6 col-xl-4 offset-1 offset-sm-2 offset-md-3 offset-xl-4 my-5 shadow px-5 py-4 bg-white rounded">
                <h2 className="text-center pb-3">Add new user</h2>
                {this.html_createUser()}
                    <div className="row">
                        <div className="col-12 text-center">
                        
                            {this.html_password()}
                            {this.html_sucess_buttons()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AdminAddUser;